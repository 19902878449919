import { useEffect } from 'react'
import { useForm, Form, FieldButton, OutlinedField } from 'client-shared/hooks/useForm/index.js'
import { type DevicesState } from '../../hooks/useDevices'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type UpdateDialogForm = {
  takePhotoSettings: Device['takePhotoSettings']
}

type Device = DevicesState['devices'][number]

export type UpdateDialogProps = {
  open: boolean
  deviceId: string
  deviceName: string
  takePhotoSettings: Device['takePhotoSettings']
  onClose: () => void
  onSubmit: (deviceId: string, args: UpdateDialogForm) => Promise<unknown>
}

export default function UpdateDialog(props: UpdateDialogProps) {
  useEffect(() => {
    if (props.open) {
      resetForm({ takePhotoSettings: props.takePhotoSettings })
    }
  }, [props.open])

  const form = useForm<UpdateDialogForm>({
    defaultValues: {
      takePhotoSettings: props.takePhotoSettings
    }
  })

  const resetForm = (options: Pick<UpdateDialogProps, 'takePhotoSettings'>) => {
    form.reset({
      takePhotoSettings: options.takePhotoSettings
    })
  }

  const onSubmit = (data: UpdateDialogForm) => {
    props.onSubmit(props.deviceId, data).then(() => props.onClose())
  }

  const takePhotoSettingsOptions: Device['takePhotoSettings'][] = ['default', 'skip']

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{
        onEnter: () => resetForm({ takePhotoSettings: props.takePhotoSettings })
      }}
    >
      <Form form={form}>
        <DialogTitle>Device編集({props.deviceName})</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <OutlinedField
              type="select"
              name="takePhotoSettings"
              label="takePhotoSettings"
              options={takePhotoSettingsOptions}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <FieldButton onClick={props.onClose}>キャンセル</FieldButton>
          <FieldButton variant="contained" onSubmit={onSubmit}>
            編集
          </FieldButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
