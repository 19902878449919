import { useEffect, useCallback } from 'react'
import { atom, useAtom } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { clients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'

type ProfileState = {
  loading: boolean
  roles: {
    systemAdmin: boolean
  }
}

const profileState = atom<ProfileState>({
  loading: false,
  roles: {
    systemAdmin: false
  }
})

export const useMyProfile = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const [profile, setProfile] = useAtom(profileState)

  const fetchMyProfile = useCallback(async () => {
    if (!user) {
      return
    }

    setProfile({
      ...profile,
      loading: true
    })
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await clients['/api/@me'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (res.status === 200) {
      setProfile({
        loading: false,
        roles: {
          systemAdmin: res.body.roles.includes('system-admin')
        }
      })
    } else {
      setProfile({
        loading: false,
        roles: {
          systemAdmin: false
        }
      })
    }

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setProfile, user])

  useEffect(() => {
    fetchMyProfile()
  }, [fetchMyProfile])

  return {
    loading: profile.loading,
    isSystemAdmin: profile.roles.systemAdmin
  }
}
