import { useCallback, useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { systemAdminClients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0.js'
import { flatten } from '../lib/util.js'

type Organization = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/organizations']['GET']['client']>
>['body']['organizations'][number]

type OrganizationsState = {
  initialized: boolean
  ids: string[]
  organizations: Record<string, Organization>
}

const organizationsState = atom<OrganizationsState>({
  initialized: false,
  ids: [],
  organizations: {}
})

export const useOrganizations = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [organizations, setOrganizations] = useAtom(organizationsState)

  const getOrganizations = useCallback(async () => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/organizations'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    const { ids, record } = flatten(res.body.organizations)
    setOrganizations({
      initialized: true,
      ids: ids,
      organizations: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setOrganizations])

  const createOrganization = async (
    body: Parameters<(typeof systemAdminClients)['/api/systemAdmin/organizations']['POST']['client']>[0]['body']
  ) => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/organizations'].POST.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      body
    })
    return res
  }

  const updateOrganization = async (
    organizationId: string,
    body: Parameters<
      (typeof systemAdminClients)['/api/systemAdmin/organizations/:organizationId']['PUT']['client']
    >[0]['body']
  ) => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/organizations/:organizationId'].PUT.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { organizationId },
      body
    })
    return res
  }

  const addOwners = async (
    organizationId: string,
    body: Parameters<
      (typeof systemAdminClients)['/api/systemAdmin/organizations/:organizationId/owners']['POST']['client']
    >[0]['body']
  ) => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/organizations/:organizationId/owners'].POST.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { organizationId },
      body
    })
    return res
  }

  useEffect(() => {
    if (organizations.initialized) {
      return
    }
    getOrganizations()
  }, [getOrganizations, organizations.initialized])

  return {
    state: organizations,
    getOrganizations,
    createOrganization,
    updateOrganization,
    addOwners
  }
}
