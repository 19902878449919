import { useCallback } from 'react'
import { atom, useAtom } from 'jotai'
import { useAuth0 } from '@auth0/auth0-react'
import { systemAdminClients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0.js'
import { flatten } from '../lib/util.js'

type User = Awaited<
  ReturnType<(typeof systemAdminClients)['/api/systemAdmin/users']['GET']['client']>
>['body']['users'][number]

type UsersState = {
  ids: string[]
  users: Record<string, User>
}

const usersState = atom<UsersState>({
  ids: [],
  users: {}
})

export const useUsers = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [users, setUsers] = useAtom(usersState)

  const getUsers = useCallback(async () => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    const res = await systemAdminClients['/api/systemAdmin/users'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    const { ids, record } = flatten(res.body.users)
    setUsers({
      ids: ids,
      users: record
    })

    return res
  }, [getAccessTokenSilently, getAccessTokenWithPopup, setUsers])

  return {
    state: users,
    getUsers
  }
}
